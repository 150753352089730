<template>
  <b-modal
    id="personal_info_mobile_modal"
    ref="bvModal"
    :no-close-on-backdrop="true"
  >
    <template v-slot:modal-header="{ close }">
      <div class="modal-header-buttons-container">
        <button
          class="close-modal-btn mr-7"
          id="personal_info_mobile_modal_close_btn"
          @click="close()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
    </template>
    <h2>{{ $t("personalInfoMobileModal.title") }}</h2>
    <div class="bb-form-group">
      <label>{{ $t("personalInfoMobileModal.genderInput.label") }}</label>
      <BBSelect :options="genderOptions" v-model="selectedGender"></BBSelect>
    </div>
    <h3>{{ $t("personalInfoMobileModal.birthdayInput.label") }}</h3>
    <BBSelect
      v-model="birthday.month"
      :placeholder="
        $t('personalInfoMobileModal.birthdayInput.monthPlaceholder')
      "
      :options="monthOptions"
      class="mb-8 birthday-input"
    ></BBSelect>
    <div class="birthday-inputs-group">
      <BBSelect
        v-model="birthday.day"
        :placeholder="
          $t('personalInfoMobileModal.birthdayInput.dayPlaceholder')
        "
        :options="dayOptions"
        class="birthday-input"
      ></BBSelect>
      <BBSelect
        v-model="birthday.year"
        :placeholder="
          $t('personalInfoMobileModal.birthdayInput.yearPlaceholder')
        "
        :options="yearOptions"
        class="birthday-input"
      ></BBSelect>
    </div>
    <template v-slot:modal-footer>
      <button class="bb-btn btn-success" @click="save()">
        {{ $t("personalInfoMobileModal.saveBtn") }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import BBSelect from "@/view/content/BBForm/BBSelect.vue";
import i18nService from "@/core/services/i18n.service.js";
import moment from "moment";

export default {
  name: "PersonalInfoMobileModal",
  components: { BBSelect },
  props: {
    /**
     * Data property
     * @type {Object}
     */
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      genderOptions: [
        {
          value: "unknown",
          text: this.$t("clientFormModal.genderInput.options.unknown")
        },
        {
          value: "male",
          text: this.$t("clientFormModal.genderInput.options.male")
        },
        {
          value: "female",
          text: this.$t("clientFormModal.genderInput.options.female")
        }
      ],
      selectedGender: "unknown",
      birthday: {
        month: null,
        day: null,
        year: null
      }
    };
  },
  beforeMount() {
    // Set moment locale
    moment.locale(i18nService.getActiveLanguage());
  },
  methods: {
    /**
     * Show the modal
     * @return {void}
     */
    show() {
      this.$refs.bvModal.show();
      if (this.data.birthday) {
        this.birthday = JSON.parse(JSON.stringify(this.data.birthday));
      }
      if (this.data.gender) {
        this.selectedGender = this.data.gender;
      }
    },
    /**
     * Close the modal
     * @return {void}
     */
    closeModal() {
      document.querySelector("#personal_info_mobile_modal_close_btn").click();
    },

    /**
     * Emit dataChanged event and close modal
     * @emits dataChanged
     */
    save() {
      this.$emit("dataChanged", {
        birthday: this.birthday,
        gender: this.selectedGender
      });
      this.closeModal();
    }
  },
  computed: {
    /**
     * Return array of month options
     * @return {Array}
     */
    monthOptions() {
      let monthOptions = [];
      moment.months().forEach(month => {
        let option = {
          text: month,
          value: moment()
            .month(month)
            .format("M")
        };
        monthOptions.push(option);
      });

      return monthOptions;
    },

    /**
     * Return array of day options
     * @return {Array}
     */
    dayOptions() {
      let dayOptions = [];
      let year = this.birthday.year
        ? this.birthday.year
        : moment().format("YYYY");
      let month = this.birthday.month ? this.birthday.month : "1";
      month = parseInt(month) < 10 ? "0" + month : month;
      let daysCount = moment(year + "-" + month).daysInMonth();
      for (let i = 1; i <= daysCount; i++) {
        let option = {
          value: i.toString(),
          text: i.toString()
        };
        dayOptions.push(option);
      }
      return dayOptions;
    },

    /**
     * Return array of year options
     * @return {Array}
     */
    yearOptions() {
      let yearOptions = [];
      let endYear = parseInt(moment().format("YYYY"));
      let startYear = endYear - 100;
      for (let i = startYear; i <= endYear; i++) {
        let option = {
          text: i.toString(),
          value: i.toString()
        };
        yearOptions.push(option);
      }

      return yearOptions;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/modals/personal-info-mobile-modal.scss";
</style>
