<template>
  <b-form-checkbox
    class="bb-checkbox"
    size="lg"
    v-model="passedValue"
    @input="onCheck($event)"
    :disabled="disabled"
  >
    {{ label }}
  </b-form-checkbox>
</template>

<script>
export default {
  name: "BBCheckbox",
  props: {
    /**
     * Checkbox label
     * @type {String}
     */
    label: {
      type: String,
      required: true
    },

    /**
     * Initial value for checkbox
     * @type {Boolean}
     */
    value: {
      type: Boolean,
      required: true
    },

    /**
     * Disabled
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      requred: false,
      default: false
    }
  },
  methods: {
    /**
     * Emits input event for new value for checkbox
     * @param {Boolean} newValue
     * @emits input
     */
    onCheck(newValue) {
      this.$emit("input", newValue);
    }
  },
  computed: {
    /**
     * Represent initial value passed trought props and set new value
     */
    passedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        return newValue;
      }
    }
  }
};
</script>

<style></style>
