const clientForm = {
  /**
   * Client form key
   * @type {String}
   */
  key: "clientForm",

  /**
   * Client form fields object
   * @type {Object}
   */
  fields: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    mobilePhone: "",
    notificationsEnabled: true,
    notificationsSentBy: "email",
    address: "",
    state: "",
    city: "",
    zipCode: "",
    notes: "",
    displayNotes: false,
    gender: "unknown",
    birthday: ""
  },

  /**
   * Client form helpers object
   * @type {Object}
   */
  helpers: {
    birthday: {
      month: null,
      day: null,
      year: null
    },
    notificationsSentBy: "email"
  },

  /**
   * Client form errors object
   * @type {Object}
   */
  errors: {},

  /**
   * Client form mode
   * @type {String}
   */
  mode: "create",

  /**
   * Client form path
   * @type {String}
   */
  path: "/api/b2b/client-profiles"
};

export default clientForm;
