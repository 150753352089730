import clientForm from "@/core/services/forms/clientForm.js";
import { INIT_FORM } from "@/core/services/store/form.module.js";
import moment from "moment";

export default {
  methods: {
    /**
     * Init client form with default values from clientForm object and open client form element
     * @param {Object|null} fields
     * @return {void}
     */
    createClient(fields = null) {
      let form = JSON.parse(JSON.stringify(clientForm));
      if (fields) {
        for (const [key, value] of Object.entries(fields)) {
          form.fields[key] = value;
        }
      }
      this.$store.dispatch(INIT_FORM, form);
      if (this.$refs.clientFormModal) {
        this.$refs.clientFormModal.show();
      }
    },

    /**
     * Init client form with edit mode and open client form element
     * @param {Object} client
     * @return {void}
     */
    editClient(client) {
      let form = JSON.parse(JSON.stringify(clientForm));
      form.mode = "edit";
      form.path = form.path + "/" + client.id;
      for (const [key, value] of Object.entries(client)) {
        form.fields[key] = value;
      }
      form.fields._method = "PUT";
      form.helpers = this.updateClientFormHelpers(form.helpers, client);
      this.$store.dispatch(INIT_FORM, form);
      if (this.$refs.clientFormModal) {
        this.$refs.clientFormModal.show();
      }
    },

    /**
     * Update form helpers object
     * @param {Object} formHelpers
     * @param {Object} client
     * @return {Object}
     */
    updateClientFormHelpers(formHelpers, client) {
      formHelpers.notificationsSentBy = client.notificationsSentBy
        ? client.notificationsSentBy
        : "dont_send";
      if (client.birthday) {
        formHelpers.birthday = {
          day: moment(client.birthday).format("D"),
          month: moment(client.birthday).format("M"),
          year: moment(client.birthday).format("YYYY")
        };
      }

      return formHelpers;
    }
  }
};
