<template>
  <b-modal id="client_form_modal" ref="bvModal" :no-close-on-backdrop="true">
    <template v-slot:modal-header="{ close }">
      <div class="modal-header-buttons-container">
        <div class="placeholder"></div>
        <h2>{{ modalTitle }}</h2>
        <button
          class="close-modal-btn mr-7"
          id="client_form_modal_close_btn"
          @click="close()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
    </template>
    <div class="modal-body-section">
      <div class="modal-inputs-holder">
        <div class="form-groups-container">
          <div class="bb-form-group">
            <label>{{ $t("clientFormModal.firstNameInput.label") }}</label>
            <input
              class="bb-input"
              type="text"
              v-model="form.fields.firstName"
            />
            <div class="bb-invalid-feedback" v-show="form.errors.firstName">
              {{ $t("clientFormModal.validation.error") }}
            </div>
          </div>
          <div class="bb-form-group">
            <label>{{ $t("clientFormModal.lastNameInput.label") }}</label>
            <input
              class="bb-input"
              type="text"
              v-model="form.fields.lastName"
            />
            <div class="bb-invalid-feedback" v-show="form.errors.lastName">
              {{ $t("clientFormModal.validation.error") }}
            </div>
          </div>
        </div>
        <div class="form-groups-container">
          <div class="bb-form-group">
            <label>{{ $t("clientFormModal.mobilePhoneInput.label") }}</label>
            <input
              class="bb-input"
              type="text"
              v-model="form.fields.mobilePhone"
            />
            <div class="bb-invalid-feedback" v-show="form.errors.mobilePhone">
              {{ $t("clientFormModal.validation.error") }}
            </div>
          </div>
          <div class="bb-form-group">
            <label>{{ $t("clientFormModal.phoneInput.label") }}</label>
            <input class="bb-input" type="text" v-model="form.fields.phone" />
            <div class="bb-invalid-feedback" v-show="form.errors.phone">
              {{ $t("clientFormModal.validation.error") }}
            </div>
          </div>
        </div>
        <div class="bb-form-group">
          <label>{{ $t("clientFormModal.emailInput.label") }}</label>
          <input class="bb-input" type="email" v-model="form.fields.email" />
          <div class="bb-invalid-feedback" v-show="form.errors.email">
            {{ $t("clientFormModal.validation.error") }}
          </div>
        </div>
        <div class="bb-form-group">
          <label>{{
            $t("clientFormModal.sendNotificationsByInput.label")
          }}</label>
          <BBSelect
            :options="sendNotificationsOptions"
            v-model="form.helpers.notificationsSentBy"
          ></BBSelect>
          <div
            class="bb-invalid-feedback"
            v-show="form.errors.notificationsSentBy"
          >
            {{ $t("clientFormModal.validation.error") }}
          </div>
        </div>
        <div class="bb-form-group">
          <BBCheckbox
            v-model="form.fields.notificationsEnabled"
            :label="$t('clientFormModal.notificationsEnabledInput.label')"
          />
          <div
            class="bb-invalid-feedback"
            v-show="form.errors.notificationsEnabled"
          >
            {{ $t("clientFormModal.validation.error") }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal-body-section">
      <div class="modal-inputs-holder">
        <v-tabs>
          <v-tab>{{ $t("clientFormModal.tabs.personalInfo") }}</v-tab>
          <v-tab>{{ $t("clientFormModal.tabs.address") }}</v-tab>
          <v-tab-item>
            <div class="bb-form-group">
              <label>{{ $t("clientFormModal.genderInput.label") }}</label>
              <BBSelect
                v-model="form.fields.gender"
                :options="genderOptions"
              ></BBSelect>
              <div class="bb-invalid-feedback" v-show="form.errors.gender">
                {{ $t("clientFormModal.validation.error") }}
              </div>
            </div>
            <div class="bb-form-group">
              <label>{{ $t("clientFormModal.birthdayInput.label") }}</label>
              <div class="birthday-inputs">
                <BBSelect
                  v-model="form.helpers.birthday.month"
                  :placeholder="
                    $t('clientFormModal.birthdayInput.monthPlaceholder')
                  "
                  :options="monthOptions"
                ></BBSelect>
                <BBSelect
                  v-model="form.helpers.birthday.day"
                  :placeholder="
                    $t('clientFormModal.birthdayInput.dayPlaceholder')
                  "
                  :options="dayOptions"
                ></BBSelect>
                <BBSelect
                  v-model="form.helpers.birthday.year"
                  :placeholder="
                    $t('clientFormModal.birthdayInput.yearPlaceholder')
                  "
                  :options="yearOptions"
                ></BBSelect>
              </div>
              <div class="bb-invalid-feedback" v-show="form.errors.birthday">
                {{ $t("clientFormModal.validation.error") }}
              </div>
            </div>
            <div class="bb-form-group">
              <label>{{ $t("clientFormModal.notesInput.label") }}</label>
              <b-form-textarea
                rows="3"
                class="bb-textarea"
                v-model="form.fields.notes"
              ></b-form-textarea>
              <div class="bb-invalid-feedback" v-show="form.errors.notes">
                {{ $t("clientFormModal.validation.error") }}
              </div>
            </div>
            <div class="bb-form-group">
              <BBCheckbox
                v-model="form.fields.displayNotes"
                :disabled="!form.fields.notes"
                :label="$t('clientFormModal.displayNotesInput.label')"
              />
              <div
                class="bb-invalid-feedback"
                v-show="form.errors.displayNotes"
              >
                {{ $t("clientFormModal.validation.error") }}
              </div>
            </div>
          </v-tab-item>
          <v-tab-item >
            <div class="bb-form-group">
              <label>{{ $t("clientFormModal.addressInput.label") }}</label>
              <input
                type="text"
                class="bb-input"
                v-model="form.fields.address"
              />
              <div class="bb-invalid-feedback" v-show="form.errors.address">
                {{ $t("clientFormModal.validation.error") }}
              </div>
            </div>
            <div class="form-groups-container">
              <div class="bb-form-group">
                <label>{{ $t("clientFormModal.cityInput.label") }}</label>
                <input
                  class="bb-input"
                  type="text"
                  v-model="form.fields.city"
                />
                <div class="bb-invalid-feedback" v-show="form.errors.city">
                  {{ $t("clientFormModal.validation.error") }}
                </div>
              </div>
              <div class="bb-form-group">
                <label>{{ $t("clientFormModal.stateInput.label") }}</label>
                <input
                  class="bb-input"
                  type="text"
                  v-model="form.fields.state"
                />
                <div class="bb-invalid-feedback" v-show="form.errors.state">
                  {{ $t("clientFormModal.validation.error") }}
                </div>
              </div>
            </div>
            <div class="bb-form-group">
              <label>{{ $t("clientFormModal.zipCodeInput.label") }}</label>
              <input
                type="text"
                class="bb-input"
                v-model="form.fields.zipCode"
              />
              <div class="bb-invalid-feedback" v-show="form.errors.zipCode">
                {{ $t("clientFormModal.validation.error") }}
              </div>
            </div>
          </v-tab-item>
        </v-tabs>
        <div class="mobile-additional-info-container">
          <div class="mobile-section">
            <h3>
              {{ $t("clientFormModal.mobileSection.notesSection.title") }}
            </h3>
            <div class="bb-form-group">
              <label>{{
                $t(
                  "clientFormModal.mobileSection.notesSection.notesInput.label"
                )
              }}</label>
              <b-form-textarea
                rows="3"
                class="bb-textarea"
                v-model="form.fields.notes"
              ></b-form-textarea>
              <div class="bb-invalid-feedback" v-show="form.errors.notes">
                {{ $t("clientFormModal.validation.error") }}
              </div>
            </div>
            <div class="bb-form-group">
              <BBCheckbox
                v-model="form.fields.displayNotes"
                :disabled="!form.fields.notes"
                :label="
                  $t(
                    'clientFormModal.mobileSection.notesSection.displayNotesInput.label'
                  )
                "
              />
              <div
                class="bb-invalid-feedback"
                v-show="form.errors.displayNotes"
              >
                {{ $t("clientFormModal.validation.error") }}
              </div>
            </div>
          </div>
          <div class="mobile-section">
            <h3>
              {{
                $t("clientFormModal.mobileSection.additionalInfoSection.title")
              }}
            </h3>
            <div class="modal-trigger" @click="openPersonalInfoModal()">
              <span>{{
                $t(
                  "clientFormModal.mobileSection.additionalInfoSection.personalInfoBtn"
                )
              }}</span>
              <i class="la la-angle-right"></i>
            </div>
            <div class="modal-trigger" @click="openAddressDetailsModal()">
              <span>{{
                $t(
                  "clientFormModal.mobileSection.additionalInfoSection.addressDetailsBtn"
                )
              }}</span>
              <i class="la la-angle-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer="{ close }">
      <div class="footer-left" v-show="footerLeftVisible">
        <button
          class="bb-btn btn-danger"
          v-show="form.mode === 'edit'"
          @click="deleteClient()"
        >
          {{ $t("clientFormModal.deleteBtn") }}
        </button>
      </div>
      <div class="footer-right">
        <button class="bb-btn btn-outline" @click="close()">
          {{ $t("clientFormModal.closeBtn") }}
        </button>
        <button class="bb-btn btn-success" @click="submit()">
          {{ $t("clientFormModal.saveBtn") }}
        </button>
      </div>
    </template>
    <PersonalInfoMobileModal
      ref="personalInfoMobileModal"
      :data="{ birthday: form.helpers.birthday, gender: form.fields.gender }"
      @dataChanged="personalInfoChanged"
    />
    <AddressDetailsMobileModal
      ref="addressDetailsMobileModal"
      :data="{
        address: form.fields.address,
        city: form.fields.city,
        state: form.fields.state,
        zipCode: form.fields.zipCode,
      }"
      @dataChanged="addressDetailsChanged"
    />
  </b-modal>
</template>

<script>
import clientForm from "@/core/services/forms/clientForm.js";
import formMixin from "@/core/mixins/formMixin.js";
import BBSelect from "@/view/content/BBForm/BBSelect.vue";
import BBCheckbox from "@/view/content/BBForm/BBCheckbox.vue";
import i18nService from "@/core/services/i18n.service.js";
import moment from "moment";
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import { GET_CLIENTS } from "@/core/services/store/clients.module";
import PersonalInfoMobileModal from "@/view/pages/components/Client/PersonalInfoMobileModal.vue";
import AddressDetailsMobileModal from "@/view/pages/components/Client/AddressDetailsMobileModal.vue";
import { EventBus } from "@/core/services/event-bus.js";

export default {
  name: "ClientFormModal",
  mixins: [formMixin],
  components: {
    BBSelect,
    BBCheckbox,
    PersonalInfoMobileModal,
    AddressDetailsMobileModal,
  },
  data() {
    return {
      sendNotificationsOptions: [
        {
          text: this.$t(
            "clientFormModal.sendNotificationsByInput.options.email"
          ),
          value: "email",
        },
        {
          text: this.$t(
            "clientFormModal.sendNotificationsByInput.options.dontSend"
          ),
          value: "dont_send",
        },
      ],
      genderOptions: [
        {
          value: "unknown",
          text: this.$t("clientFormModal.genderInput.options.unknown"),
        },
        {
          value: "male",
          text: this.$t("clientFormModal.genderInput.options.male"),
        },
        {
          value: "female",
          text: this.$t("clientFormModal.genderInput.options.female"),
        },
      ],
    };
  },
  beforeMount() {
    // Get form object from clientForm service
    this.form = JSON.parse(JSON.stringify(clientForm));
    // Set moment locale
    moment.locale(i18nService.getActiveLanguage());
  },
  methods: {
    /**
     * Show the modal
     * @return {void}
     */
    show() {
      this.$refs.bvModal.show();
    },
    /**
     * Close the modal
     * @return {void}
     */
    closeModal() {
      document.querySelector("#client_form_modal_close_btn").click();
    },

    /**
     * Update form fields from form helpers
     * @return {void}
     */
    updateFormFields() {
      this.form.fields.notificationsSentBy =
        this.form.helpers.notificationsSentBy === "email"
          ? this.form.helpers.notificationsSentBy
          : null;
      if (
        this.form.helpers.birthday.month &&
        this.form.helpers.birthday.day &&
        this.form.helpers.birthday.year
      ) {
        let month =
          parseInt(this.form.helpers.birthday.month) < 10
            ? "0" + this.form.helpers.birthday.month
            : this.form.helpers.birthday.month;
        let day =
          parseInt(this.form.helpers.birthday.day) < 10
            ? "0" + this.form.helpers.birthday.day
            : this.form.helpers.birthday.day;
        this.form.fields.birthday = moment(
          this.form.helpers.birthday.year + "-" + month + "-" + day
        ).format("YYYY-MM-DD");
      }
    },

    /**
     * Submit the form
     * @return {void}
     */
    submit() {
      this.updateFormFields();
      this.submitForm()
        .then((response) => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null,
          });
          this.closeModal();
          if (this.$route.name === "clientProfile") {
            this.$emit("clientUpdated");
          } else {
            this.$store.dispatch(GET_CLIENTS);
          }
        })
        .catch((error) =>
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message],
          })
        );
    },

    /**
     * Delete the client
     * @return {void}
     */
    deleteClient() {
      let client = this.$store.getters.getClient(this.form.fields.id);

      EventBus.$emit("deletingClient", {
        client: client,
        successCallback: function () {
          document.querySelector("#client_form_modal_close_btn").click();
          this.$store.dispatch(GET_CLIENTS);
        },
      });
    },

    /**
     * Open personal info mobile modal
     * @return {void}
     */
    openPersonalInfoModal() {
      this.$refs.personalInfoMobileModal.show();
    },

    /**
     * Open address details mobile modal
     * @return {void}
     */
    openAddressDetailsModal() {
      this.$refs.addressDetailsMobileModal.show();
    },

    /**
     * Handle personal info dataChanged event
     * @param {Object} data
     * @return {void}
     */
    personalInfoChanged(data) {
      this.form.helpers.birthday = data.birthday;
      this.form.fields.gender = data.gender;
    },

    /**
     * Handle address details dataChanged event
     * @param {Object} data
     * @return {void}
     */
    addressDetailsChanged(data) {
      this.form.fields.address = data.address;
      this.form.fields.city = data.city;
      this.form.fields.state = data.state;
      this.form.fields.zipCode = data.zipCode;
    },
  },
  computed: {
    /**
     * Return array of month options
     * @return {Array}
     */
    monthOptions() {
      let monthOptions = [];
      moment.months().forEach((month) => {
        let option = {
          text: month,
          value: moment().month(month).format("M"),
        };
        monthOptions.push(option);
      });

      return monthOptions;
    },

    /**
     * Return array of day options
     * @return {Array}
     */
    dayOptions() {
      let helper = JSON.parse(JSON.stringify(this.form.helpers.birthday));
      let dayOptions = [];
      let year = helper.year ? helper.year : moment().format("YYYY");
      let month = helper.month ? helper.month : "1";
      month = parseInt(month) < 10 ? "0" + month : month;
      let daysCount = moment(year + "-" + month).daysInMonth();
      for (let i = 1; i <= daysCount; i++) {
        let option = {
          value: i.toString(),
          text: i.toString(),
        };
        dayOptions.push(option);
      }
      return dayOptions;
    },

    /**
     * Return array of year options
     * @return {Array}
     */
    yearOptions() {
      let yearOptions = [];
      let endYear = parseInt(moment().format("YYYY"));
      let startYear = endYear - 100;
      for (let i = startYear; i <= endYear; i++) {
        let option = {
          text: i.toString(),
          value: i.toString(),
        };
        yearOptions.push(option);
      }

      return yearOptions;
    },

    /**
     * Return modal title
     * @return {String}
     */
    modalTitle() {
      return this.form.mode === "edit"
        ? this.$t("clientFormModal.editTitle")
        : this.$t("clientFormModal.createTitle");
    },

    /**
     * Determine if left footer section is visible
     * @return {Boolean}
     */
    footerLeftVisible() {
      if (window.innerWidth < 992) {
        return this.form.mode === "edit";
      }
      return true;
    },
  },
  watch: {
    "form.fields.notes": {
      handler() {
        if (!this.form.fields.notes) {
          this.form.fields.displayNotes = false;
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/modals/client-form-modal.scss";
</style>
