<template>
  <b-modal
    id="address_details_mobile_modal"
    ref="bvModal"
    :no-close-on-backdrop="true"
  >
    <template v-slot:modal-header="{ close }">
      <div class="modal-header-buttons-container">
        <button
          class="close-modal-btn mr-7"
          id="address_details_mobile_modal_close_btn"
          @click="close()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
    </template>
    <h2>{{ $t("addressDetailsMobileModal.title") }}</h2>
    <div class="bb-form-group">
      <label>{{ $t("addressDetailsMobileModal.addressInput.label") }}</label>
      <input type="text" class="bb-input" v-model="address" />
    </div>
    <div class="bb-form-group">
      <label>{{ $t("addressDetailsMobileModal.cityInput.label") }}</label>
      <input type="text" class="bb-input" v-model="city" />
    </div>
    <div class="bb-form-group">
      <label>{{ $t("addressDetailsMobileModal.stateInput.label") }}</label>
      <input type="text" class="bb-input" v-model="state" />
    </div>
    <div class="bb-form-group">
      <label>{{ $t("addressDetailsMobileModal.zipCodeInput.label") }}</label>
      <input type="text" class="bb-input" v-model="zipCode" />
    </div>
    <template v-slot:modal-footer>
      <button class="bb-btn btn-success" @click="save()">
        {{ $t("addressDetailsMobileModal.saveBtn") }}
      </button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "AddressDetailsMobileModal",
  props: {
    /**
     * Data property
     * @type {Object}
     */
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      address: "",
      state: "",
      city: "",
      zipCode: ""
    };
  },
  methods: {
    /**
     * Show the modal
     * @return {void}
     */
    show() {
      this.$refs.bvModal.show();
      if (this.data.address) {
        this.address = this.data.address;
      }
      if (this.data.state) {
        this.state = this.data.state;
      }
      if (this.data.city) {
        this.city = this.data.city;
      }
      if (this.data.zipCode) {
        this.zipCode = this.data.zipCode;
      }
    },

    /**
     * Close the modal
     * @return {void}
     */
    closeModal() {
      document.querySelector("#address_details_mobile_modal_close_btn").click();
    },

    /**
     * Emit dataChanged event and close modal
     * @emits dataChanged
     */
    save() {
      this.$emit("dataChanged", {
        address: this.address,
        state: this.state,
        city: this.city,
        zipCode: this.zipCode
      });
      this.closeModal();
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/modals/address-details-mobile-modal.scss";
</style>
